var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-wrapper"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_c('div',{staticClass:"info-top"},[_c('h6',[_vm._v(" Add Customer Information ")]),_c('router-link',{staticClass:"custom-btn4",attrs:{"to":"/customers"}},[_c('span',[_vm._v("Back")])])],1),_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 col-lg-2 px-0 pr-md-2"},[_c('div',{staticClass:"user-profile-image"},[_c('div',{staticClass:"profile-image"},[_c('div',{staticClass:"profile-image",attrs:{"id":"preview"}},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_c('img',{attrs:{"src":require("../../../assets/images/user-image.png"),"alt":""}})])]),_c('div',{staticClass:"change-profile-image",on:{"click":function($event){return _vm.openinput()}}},[_c('form',[_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"vue-file-upload-input-profile-photo","accept":"image/png, image/gif, image/jpeg"},on:{"change":_vm.onFileChange}}),_vm._m(0)])])])]),_c('div',{staticClass:"col-12 col-md-8 col-lg-10 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.customer_name.$model),expression:"$v.details.customer_name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.customer_name.$error,
                        'is-valid': !_vm.$v.details.customer_name.$invalid,
                      },attrs:{"type":"text","id":"customer_name","placeholder":"Customer Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.customer_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.customer_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.customer_name.required)?_c('span',[_vm._v("Customer name is required")]):_vm._e(),(!_vm.$v.details.customer_name.minLength)?_c('span',[_vm._v("Customer name must have at least "+_vm._s(_vm.$v.details.customer_name.$params.minLength.min))]):_vm._e(),(!_vm.$v.details.customer_name.maxLength)?_c('span',[_vm._v("Customer name must have at most "+_vm._s(_vm.$v.details.customer_name.$params.maxLength.max))]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.contact_person.$model),expression:"$v.details.contact_person.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.contact_person.$error,
                        'is-valid': !_vm.$v.details.contact_person.$invalid,
                      },attrs:{"type":"text","id":"contact_person","placeholder":"Contact Person","autocomplete":"off"},domProps:{"value":(_vm.$v.details.contact_person.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.contact_person, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.contact_person.required)?_c('span',[_vm._v("Contact Person is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.contact_first_name.$model),expression:"$v.details.contact_first_name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.contact_first_name.$error,
                        'is-valid': !_vm.$v.details.contact_first_name.$invalid,
                      },attrs:{"type":"text","id":"contact_first_name","placeholder":"Contact First Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.contact_first_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.contact_first_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.contact_first_name.required)?_c('span',[_vm._v("Contact First Name is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.address.$model),expression:"$v.details.address.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.address.$error,
                        'is-valid': !_vm.$v.details.address.$invalid,
                      },attrs:{"type":"text","id":"address","placeholder":"Address","autocomplete":"off"},domProps:{"value":(_vm.$v.details.address.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.address, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e()])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.phone_primary.$model),expression:"$v.details.phone_primary.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.phone_primary.$error,
                    'is-valid': !_vm.$v.details.phone_primary.$invalid,
                  },attrs:{"type":"text","id":"phone_primary","placeholder":"Phone (primary)","autocomplete":"off"},domProps:{"value":(_vm.$v.details.phone_primary.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.phone_primary, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.phone_primary.required)?_c('span',[_vm._v("Phone number (primary) is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-4 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.phone_alternative.$model),expression:"$v.details.phone_alternative.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.phone_alternative.$error,
                    'is-valid': !_vm.$v.details.phone_alternative.$invalid,
                  },attrs:{"type":"text","id":"phone_alternative","placeholder":"Phone (alternative)","autocomplete":"off"},domProps:{"value":(_vm.$v.details.phone_alternative.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.phone_alternative, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.phone_alternative.required)?_c('span',[_vm._v("Phone number (alternative) is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-4 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(7),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.fax.$model),expression:"$v.details.fax.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.fax.$error,
                    'is-valid': !_vm.$v.details.fax.$invalid,
                  },attrs:{"type":"text","id":"fax","placeholder":"Fax","autocomplete":"off"},domProps:{"value":(_vm.$v.details.fax.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.fax, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.fax.required)?_c('span',[_vm._v("Fax number is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(8),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.email.$error,
                    'is-valid': !_vm.$v.details.email.$invalid,
                  },attrs:{"type":"email","id":"email","placeholder":"Email","autocomplete":"off"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.details.email.email)?_c('span',[_vm._v("Email must be valid ")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(9),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.info.$model),expression:"$v.details.info.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.info.$error,
                    'is-valid': !_vm.$v.details.info.$invalid,
                  },attrs:{"type":"text","id":"info","placeholder":"Additional Printed Info","autocomplete":"off"},domProps:{"value":(_vm.$v.details.info.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.info, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.info.required)?_c('span',[_vm._v("Additional Printed Info is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"form-group d-flex align-items-center justify-content-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.status),expression:"details.status"}],staticClass:"mr-2",attrs:{"type":"checkbox","id":"customerActive"},domProps:{"checked":Array.isArray(_vm.details.status)?_vm._i(_vm.details.status,null)>-1:(_vm.details.status)},on:{"change":function($event){var $$a=_vm.details.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.details, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.details, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.details, "status", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"customerActive"}},[_vm._v("This customer is active")])])])])])])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.saveData(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-icon"},[_c('span',{staticClass:"text"},[_vm._v("Add Profile Picture")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"customer_name"}},[_vm._v("Customer Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v("Contact Person "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_first_name"}},[_vm._v("Contact First Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"address"}},[_vm._v("Address "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phone_primary"}},[_vm._v("Phone (primary) "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phone_alternative"}},[_vm._v("Phone (alternative) "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"fax"}},[_vm._v("Fax "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_vm._v("Email "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"info"}},[_vm._v("Additional Printed Info "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
}]

export { render, staticRenderFns }